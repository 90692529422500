import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import useForm, {
  FormContext,
} from 'react-hook-form/dist/react-hook-form.ie11';
import { useSelector } from 'react-redux';
import { animated as a, useSpring } from 'react-spring/web.cjs';

import countries from '../../data/countries';
import useMeasure from '../../hooks/useMeasure';
import useTranslation from '../../hooks/useTranslation';
import Input from '../helpers/input';
import Select from '../helpers/select';
import { services } from '../services/services/index';
import Accordion from '../shared/accordion';

const allCountry = [
  {
    label: `Cameroun`,
  },
  ...countries,
];

const Form = ({ data, setCurrent, setData }) => {
  const [valid, setValid] = useState(false);
  const [active, setActive] = useState(false);
  const methods = useForm();
  const [bind, { height }] = useMeasure();
  const spring = useSpring({
    maxHeight: active ? `${height}px` : `0px`,
  });
  const [error, setError] = useState(``);
  const t = useTranslation();
  const { locale } = useSelector(state => state.i18n);
  const [status, setStatus] = useState(null);

  const required = {
    required: t(`form.required`),
  };

  useEffect(() => {
    if (status) {
      setActive(true);
    }
  }, [status]);

  useEffect(() => {
    if (data.services) {
      methods.setValue(`services`, data.services);
    }
  }, []);

  useEffect(() => {
    if (data.status) {
      setStatus(data.status);
      methods.setValue(`status`, data.status);
    }
  }, []);

  useEffect(() => {
    if (data.contract) {
      methods.setValue(`contract`, data.contract);
    }
  }, []);

  useEffect(() => {
    if (data.company) {
      setActive(true);
    }
  }, [data.company]);

  useEffect(() => {
    if (data.valid) {
      setValid(data.valid);
    }
  }, [data.valid]);

  const onCheck = () => setActive(!active);

  const onSubmit = methods.handleSubmit(fields => {
    if (valid) {
      setData({
        ...data,
        ...fields,
        valid,
      });
      setCurrent(1);
    } else {
      setError(`Veuillez sélectionner un service.`);
    }
  });

  const addService = service => {
    if (data.services.includes(service)) {
      const newServices = data.services.filter(item => item !== service);
      setData({
        ...data,
        services: newServices,
      });
    } else {
      setData({
        ...data,
        services: [...data.services, service],
      });
    }
  };

  const renderServices = () => (
    <ul className="Booking__services__list">
      {services[locale].map((service, index) => (
        <li key={index}>
          <article className="Booking__services__article">
            <Accordion
              heading={<h3>{service.title}</h3>}
              defaultOpen={index === 0}
              key={index}
            >
              {service.items.map(
                (item, index) =>
                  item !== `Espace de détente` &&
                  item !== `Cafétaria` &&
                  item !== `Accès WIFI inclus` && (
                    <div className="Booking__services__input">
                      <input
                        id={item}
                        type="checkbox"
                        key={index}
                        value={item}
                        name="services"
                        ref={methods.register}
                      />
                      <label htmlFor={item}>{item}</label>
                    </div>
                  )
              )}
            </Accordion>
          </article>
        </li>
      ))}
    </ul>
  );

  return (
    <section>
      <FormContext {...methods}>
        <form className="Booking__form" onSubmit={onSubmit}>
          <div className="Booking__checkbox">
            <div>
              <input
                type="radio"
                name="status"
                value={t(`booking.private`)}
                onChange={e => setStatus(e.target.value)}
                id="statusPrivate"
                ref={methods.register}
              />
            </div>
            <label htmlFor="statusPrivate">{t(`booking.private`)}</label>
            <div>
              <input
                type="radio"
                name="status"
                value={t(`booking.pro`)}
                onChange={e => setStatus(e.target.value)}
                id="statusCompany"
                ref={methods.register}
              />
            </div>
            <label htmlFor="statusCompany">{t(`booking.pro`)}</label>
          </div>
          <a.div style={spring} className="Booking__company">
            <div {...bind}>
              <div>
                <div className="Booking__checkbox">
                  <div>
                    <input
                      id="alreadyContract"
                      type="radio"
                      value={t(`booking.alreadyContract`)}
                      name="contract"
                      ref={methods.register}
                    />
                  </div>
                  <label htmlFor="alreadyContract">
                    {t(`booking.alreadyContract`)}
                  </label>
                  <div>
                    <input
                      id="subsidiaryGroup"
                      type="radio"
                      value={t(`booking.subsidiaryGroup`)}
                      name="contract"
                      ref={methods.register}
                    />
                  </div>
                  <label htmlFor="subsidiaryGroup">
                    {t(`booking.subsidiaryGroup`)}
                  </label>
                </div>
                {status === t(`booking.pro`) && (
                  <Input
                    label={t(`booking.company`)}
                    name="company"
                    value={data.company}
                  />
                )}
              </div>
            </div>
          </a.div>
          <Input
            label={t(`form.firstName`)}
            name="firstname"
            validation={required}
            value={data.firstname}
          />
          <Input
            label={t(`form.lastName`)}
            name="lastname"
            validation={required}
            value={data.lastname}
          />
          <Input
            type="email"
            label={t(`form.email`)}
            name="email"
            validation={required}
            value={data.email}
          />
          <Input
            label={t(`form.phone`)}
            name="phone"
            validation={required}
            value={data.phone}
          />
          <Input
            label={t(`booking.date`)}
            name="date"
            validation={required}
            value={data.date}
          />
          <Input
            label={t(`booking.hour`)}
            name="hour"
            validation={required}
            value={data.hour}
          />
          <Input
            label={t(`booking.flightNumber`)}
            name="flight"
            validation={required}
            value={data.flight}
          />
          <Input
            label={t(`booking.airline`)}
            name="airline"
            validation={required}
            value={data.airline}
          />
          <Select
            label={t(`form.place`)}
            name="country"
            options={allCountry}
            full
            tooltip={t(`contact.info`)}
          />
          <div className="Booking__services">
            <h3>{t(`booking.services`)}</h3>
            <small>{t(`booking.servicesLabel`)}</small>
            {renderServices()}
          </div>
          <Input
            label={t(`booking.informations`)}
            name="informations"
            area
            full
            validation={required}
            value={data.informations}
          />
          <div className="Booking__checkbox">
            <div>
              <input
                id="valid"
                type="checkbox"
                onChange={() => setValid(!valid)}
                value={valid}
                checked={valid}
              />
            </div>
            <label htmlFor="valid">{t(`booking.accept`)}</label>
          </div>
          <div className="Contact__form__submit">
            <input type="submit" value={t(`booking.next`)} disabled={!valid} />
          </div>
        </form>
      </FormContext>
    </section>
  );
};

Form.propTypes = {
  data: PropTypes.object.isRequired,
  setCurrent: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default Form;
