import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring/web.cjs';

import Header from '../components/booking/header';
import Payment from '../components/booking/payment';
import Resume from '../components/booking/resume';
import SEO from '../components/helpers/seo';
import Layout from '../components/layout';
import Form from '../components/booking/form';
import useTranslation from '../hooks/useTranslation';

const Booking = () => {
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({});
  const t = useTranslation();

  const tabs = [
    <Form data={data} setCurrent={setCurrent} setData={setData} />,
    <Resume data={data} setCurrent={setCurrent} />,
    {
      /*<Payment data={data} />,*/
    },
  ];

  const transition = useTransition(current, p => p, {
    from: { display: `none`, opacity: 0 },
    enter: [{ display: `block` }, { opacity: 1 }],
    leave: { display: `none`, opacity: 0 },
  });

  const renderTabs = () => {
    return transition.map(({ item, props, key }) => {
      const tab = tabs[item];
      return (
        <animated.div key={key} style={props}>
          {tab}
        </animated.div>
      );
    });
  };

  return (
    <Layout
      pages={{
        en: `/en/booking`,
        fr: `/fr/reservation`,
      }}
    >
      <SEO title={t(`booking.seo`)} />
      <section className="Booking">
        <div className="container">
          <Header current={current} setCurrent={setCurrent} />
          {renderTabs()}
        </div>
      </section>
    </Layout>
  );
};

export default Booking;
