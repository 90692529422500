import React from 'react';
import * as PropTypes from 'prop-types';

import useTranslation from '../../hooks/useTranslation';

const Resume = ({ data, setCurrent }) => {
  const t = useTranslation();
  const renderServices = () => (
    <ul className="Resume__services">
      {data.services &&
        data.services.map((service, index) => (
          <li key={index}>
            <article>
              <h4>{service}</h4>
            </article>
          </li>
        ))}
    </ul>
  );

  return (
    <section className="Resume">
      <h2>{t(`booking.summary`)}</h2>
      {Object.keys(data).length === 0 ? (
        <h2>Veuillez remplir les données</h2>
      ) : (
        <div className="Resume__container">
          <div>
            <h3>Status</h3>
            <p>
              {data.status} {data.contract}
            </p>
          </div>
          {data.company && (
            <div>
              <h3>{t(`booking.company`)}</h3>
              <p>{data.company}</p>
            </div>
          )}
          <div>
            <h3>{t(`form.firstName`)}</h3>
            <p>{data.firstname}</p>
          </div>
          <div>
            <h3>{t(`form.lastName`)}</h3>
            <p>{data.lastname}</p>
          </div>
          <div>
            <h3>{t(`form.email`)}</h3>
            <p>{data.email}</p>
          </div>
          <div>
            <h3>{t(`form.phone`)}</h3>
            <p>{data.phone}</p>
          </div>
          <div>
            <h3>{t(`booking.date`)}</h3>
            <p>{data.date}</p>
          </div>
          <div>
            <h3>{t(`booking.hour`)}</h3>
            <p>{data.hour}</p>
          </div>
          <div>
            <h3>{t(`booking.flightNumber`)}</h3>
            <p>{data.flight}</p>
          </div>
          <div>
            <h3>{t(`booking.airline`)}</h3>
            <p>{data.airline}</p>
          </div>
          <div>
            <h3>{t(`booking.destination`)}</h3>
            <p>{data.country}</p>
          </div>
          <div>
            <h3>{t(`booking.informations`)}</h3>
            <p>{data.informations}</p>
          </div>
          <div>
            <h3>Services</h3>
            {renderServices()}
          </div>
        </div>
      )}
      <div className="text-center">
        <button className="btn btn-primary" type="submit">
          {t(`form.send`)}
        </button>
      </div>
    </section>
  );
};

Resume.propTypes = {
  data: PropTypes.object.isRequired,
  setCurrent: PropTypes.func,
};

export default Resume;
