import React, { useEffect, useState } from 'react';
import { animated as a, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import polyfill from '@juggle/resize-observer';

import More from '../../svg/more.svg';

const Accordion = ({ children, defaultOpen, heading }) => {
  const [ref, { height }] = useMeasure({ polyfill });
  const [open, setOpen] = useState(false);
  const collapse = useSpring({
    maxHeight: open ? `${height}px` : `0px`,
    overflow: `hidden`,
  });
  const rotate = useSpring({
    transform: open ? `rotate(45deg)` : `rotate(0deg)`,
  });

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const toggleOpen = () => setOpen(!open);

  return (
    <div>
      <div className="Accordion__heading" onClick={toggleOpen}>
        <a.div className="Accordion__more" style={rotate}>
          <More />
        </a.div>
        {heading}
      </div>
      <a.div style={collapse}>
        <div ref={ref}>{children}</div>
      </a.div>
    </div>
  );
};

export default Accordion;
