import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import useTranslation from '../../hooks/useTranslation';

const Header = ({ current, setCurrent }) => {
  const t = useTranslation();

  const items = [`Informations`, t(`booking.summary`)];

  const renderItems = () => (
    <ul className="Booking__header__list">
      {items.map((item, index) => (
        <li key={index}>
          <article
            className={classNames(`Booking__header__article`, {
              active: current === index,
            })}
            onClick={() => setCurrent(index)}
          >
            <div>{index + 1}</div>
            <h3>{item}</h3>
          </article>
        </li>
      ))}
    </ul>
  );

  return <section className="Booking__header">{renderItems()}</section>;
};

Header.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Header;
